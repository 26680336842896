<template>
    <div class="p-grid">
        <Toast />
        <!-- <div class="p-col-12 p-pb-0">
            <Button @click="$router.go(-1)" label="Go Back" icon="pi pi-angle-left" class="p-button-text p-mr-2 p-mb-2" />
        </div> -->
        <div class="p-col-12">
            <div class="card p-fluid">
                <h4>
                    <strong>{{$t('accountinfo.edit.header')}}</strong>
                </h4>
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-5">
                        <label for="nameuser">{{$t('accountinfo.edit.name')}}</label>
                        <InputText id="nameuser" :class="`${error.name ? 'p-invalid' : ''}`" type="text" :placeholder="$t('accountinfo.edit.name')" :modelValue="mydata.name" v-model="mydata.name" />
                        <div class="text-red">{{ error.name }}</div>
                    </div>
                </div>
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-5">
                        <label for="emailuser">{{$t('accountinfo.edit.email')}}</label>
                        <InputText id="emailuser" type="text" placeholder="Email" :modelValue="mydata.email" v-model="mydata.email" disabled />
                    </div>
                </div>
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-5">
                        <label for="nameuser">{{$t('accountinfo.edit.phone')}}</label>
                        <InputText id="nameuser" :class="`${error.phone_number ? 'p-invalid' : ''}`" type="text" onkeypress="return /[0-9]/i.test(event.key)" maxlength="20" @keydown.space="preventLeadingSpace" :placeholder="$t('accountinfo.edit.phone')" :modelValue="mydata.mobile" v-model="mydata.mobile" />
                        <div class="text-red">{{ error.phone_number }}</div>
                    </div>
                </div>
                <!-- <div class="p-d-flex p-jc-end">
                    <ConfirmPopup group="popup"></ConfirmPopup>
                    <Button @click="cancel" icon="pi pi-times" label="Cancel" class="p-mr-2"></Button>
                    <Button ref="popup" @click="confirm($event)" icon="pi pi-check" label="Confirm" class="p-mr-2"></Button>
                </div> -->
                <div class="p-d-flex p-jc-between">
                    <ConfirmPopup group="popup"></ConfirmPopup>
                    <Button :label="$t('accountinfo.edit.save')" class="p-button-outlined p-mr-2 p-mb-2" @click="confirm($event)"></Button>
                    <Button :label="$t('accountinfo.edit.back')" class="p-button-outlined p-mr-2 p-mb-2" @click="$router.go(-1)"></Button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import editInfo from '../../validations/account/EditInfo';
import PasswordService from '../../service/API/PasswordService';
export default {
    data() {
        return {
            dropdownItems: [
                { name: 'male', code: 'm' },
                { name: 'female', code: 'f' },
            ],
            dropdownItem: null,
            display: false,
            position: 'center',
            visibleLeft: false,
            visibleRight: false,
            visibleTop: false,
            visibleBottom: false,
            visibleFull: false,
            products: null,
            selectedProduct: null,
            phone_number:'',
            mydata: {
                name: '',
                email: '',
                mobile:'',
            },
            error: {},
        };
    },
    created() {
        this.passwordService = new PasswordService();
    },
    methods: {
        preventLeadingSpace(e) {
            // only prevent the keypress if the value is blank
            if (!e.target.value) e.preventDefault();
            // otherwise, if the leading character is a space, remove all leading white-space
            else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, "");
        },
        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },
        cancel() {
            this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
            setTimeout(() => {
                this.$router.go(-1);
            }, 2000);
        },
        confirm(event) {
            this.$confirm.require({
                target: event.currentTarget,
                group: 'popup',
                message: '계속 진행하시겠습니까?',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: '확인',
                rejectLabel: '취소',
                accept: () => {
                    let vcheckData = {
                        name: this.mydata.name,
                        phone_number: this.mydata.mobile,
                    };
                    console.log(vcheckData);
                    const { isInvalid, error } = editInfo(vcheckData);
                    if (isInvalid) {
                        this.error = error;
                        console.log(error);
                    } else {
                        this.error = {};
                        console.log('pass');
                        this.passwordService
                            .editInfos(this.mydata.name,this.mydata.mobile)
                            .then((res) => {
                                console.warn(res);
                                localStorage.setItem('UserName', this.mydata.name);
                                localStorage.setItem('UserMobile', this.mydata.mobile);
                                this.$router.push({ name: 'EditInfo' });
                               
                                setTimeout(() => {
                                    this.$toast.add({ severity: 'info', summary: '확인하다.', detail: '업데이트 완료', life: 3000 });
                                }, 1000);
                            })
                            .catch((res) => alert(res));
                    }
                },
                reject: () => {
                    this.$toast.add({ severity: 'error', summary: '오류가 발생했습니다.', detail: '취소되었습니다.', life: 3000 });
                },
            });
        },
    },
    mounted() {
        this.mydata.name = localStorage.getItem('UserName');
        this.mydata.email = localStorage.getItem('UserEmail');
        this.mydata.mobile = localStorage.getItem('UserMobile');
    },
};
</script>

<style scoped>
.p-fluid .p-button {
    width: auto;
}
</style>
