import validator from 'validator';

const editInfo = (data) => {
  let errors = {}

  const { name , phone_number } = data;

  if (validator.isEmpty(name)) {
    errors.name = 'Please enter a name'
  }

  if (validator.isEmpty(phone_number)) {
    errors.phone_number = 'I need your phone number';
  } else if (phone_number.length < 10) {
    errors.phone_number = 'Please enter a valid mobile phone number.';
  }
  return {
    isInvalid: Object.keys(errors).length > 0,
    error: errors
  }
}

export default editInfo;